.container {
  display: grid;

  grid-template-columns: 240px 1fr;
  grid-template-rows: 72px 1fr;

  grid-template-areas:

  "sidebar head head head"
  "sidebar main main main";

  /* grid-container height = 100% of viewport height */
  height: 100vh;

    
  overflow-y: auto;
  /* height: calc(100% - 72px); */
  box-sizing: border-box;
  outline: none;
 }

 .grid-sidebar {
  grid-area: sidebar;
}
.header {
  width: 100%;
  height: 72px;
  grid-area: head;
  background-color: #FFF;
  box-shadow: 4px 4px 10px 0px #d1d1d1;
  z-index: 0;
}
.header #btn {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 26px;
  margin-right: 40px;
}
.content {
  /* padding: 20px; */
  grid-area: main;
  background-color: #FFF;
}

