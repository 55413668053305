@font-face {
  font-family: 'cloe-icons';
  src: url('./cloe-icons.woff2?6tq3f') format('woff2'),
    url('./cloe-icons.ttf?6tq3f') format('truetype'),
    url('./cloe-icons.woff?6tq3f') format('woff'),
    url('./cloe-icons.svg?6tq3f#cloe-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cloe-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_circle_outline:before {
  content: "\e959";
}
.icon-remove_circle:before {
  content: "\e95a";
}
.icon-order:before {
  content: "\e95b";
}
.icon-highlight:before {
  content: "\e954";
}
.icon-pan-tool:before {
  content: "\e955";
}
.icon-restore:before {
  content: "\e956";
}
.icon-zoom-in:before {
  content: "\e957";
}
.icon-zoom-out:before {
  content: "\e958";
}
.icon-settings-input-component:before {
  content: "\e953";
}
.icon-location-city:before {
  content: "\e952";
}
.icon-share:before {
  content: "\e951";
}
.icon-palette:before {
  content: "\e947";
}
.icon-military_tech:before {
  content: "\e948";
}
.icon-find_in_page:before {
  content: "\e949";
}
.icon-format_shapes:before {
  content: "\e94a";
}
.icon-public:before {
  content: "\e94b";
}
.icon-emoji_objects:before {
  content: "\e94c";
}
.icon-local_library:before {
  content: "\e94d";
}
.icon-campaign:before {
  content: "\e94e";
}
.icon-sunny:before {
  content: "\e94f";
}
.icon-token:before {
  content: "\e950";
}
.icon-verified:before {
  content: "\e945";
}
.icon-remove:before {
  content: "\e946";
}
.icon-glossary:before {
  content: "\e944";
}
.icon-group:before {
  content: "\e929";
}
.icon-phone_iphone1:before {
  content: "\e942";
}
.icon-reorder:before {
  content: "\e8fe";
}
.icon-report-problem:before {
  content: "\e941";
}
.icon-notifications-outlined:before {
  content: "\e93f";
}
.icon-notifications:before {
  content: "\e940";
}
.icon-feedback:before {
  content: "\e93e";
}
.icon-copy:before {
  content: "\e93d";
}
.icon-refresh:before {
  content: "\e5d5";
}
.icon-today:before {
  content: "\e8df";
}
.icon-photo:before {
  content: "\e93c";
}
.icon-playlist-add:before {
  content: "\e03b";
}
.icon-text-fields:before {
  content: "\e262";
}
.icon-arrow-down:before {
  content: "\e5db";
}
.icon-arrow-up:before {
  content: "\e5d8";
}
.icon-playlist-add-check:before {
  content: "\e065";
}
.icon-insert-chart:before {
  content: "\e93b";
}
.icon-star:before {
  content: "\e939";
}
.icon-star-border:before {
  content: "\e93a";
}
.icon-upload:before {
  content: "\e938";
}
.icon-hand-touch:before {
  content: "\e937";
}
.icon-flag-outline:before {
  content: "\e936";
}
.icon-extension:before {
  content: "\e935";
}
.icon-play-arrow:before {
  content: "\e934";
}
.icon-house:before {
  content: "\e933";
}
.icon-supervised-user-circle:before {
  content: "\e932";
}
.icon-live-help:before {
  content: "\e930";
}
.icon-textsms:before {
  content: "\e931";
}
.icon-shrink:before {
  content: "\e92f";
}
.icon-expand:before {
  content: "\e56b";
}
.icon-folder-open:before {
  content: "\e92e";
}
.icon-spinner:before {
  content: "\e981";
}
.icon-whatsapp:before {
  content: "\e92d";
}
.icon-chrome-reader-mode:before {
  content: "\e92b";
}
.icon-explore:before {
  content: "\e92c";
}
.icon-open-in-new:before {
  content: "\e89e";
}
.icon-format-list-numbered:before {
  content: "\e242";
}
.icon-play-circle-outline:before {
  content: "\e92a";
}
.icon-assignment:before {
  content: "\e943";
}
.icon-send:before {
  content: "\e927";
}
.icon-replay:before {
  content: "\e928";
}
.icon-print:before {
  content: "\e926";
}
.icon-arrow-right-circle:before {
  content: "\e925";
}
.icon-person:before {
  content: "\e924";
}
.icon-key:before {
  content: "\e923";
}
.icon-add:before {
  content: "\e921";
}
.icon-more-horiz:before {
  content: "\e922";
}
.icon-bookmark:before {
  content: "\e91c";
}
.icon-favorite:before {
  content: "\e91d";
}
.icon-bookmark-border:before {
  content: "\e91e";
}
.icon-favorite-border:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-ballot:before {
  content: "\e91b";
}
.icon-schedule:before {
  content: "\e91a";
}
.icon-message:before {
  content: "\e919";
}
.icon-lock-open:before {
  content: "\e898";
}
.icon-lock:before {
  content: "\e918";
}
.icon-description:before {
  content: "\e917";
}
.icon-done-outline:before {
  content: "\e915";
}
.icon-link:before {
  content: "\e157";
}
.icon-news:before {
  content: "\e914";
}
.icon-check-box:before {
  content: "\e913";
}
.icon-check-box-blank:before {
  content: "\e835";
}
.icon-stars:before {
  content: "\e912";
}
.icon-timer:before {
  content: "\e425";
}
.icon-create:before {
  content: "\e911";
}
.icon-filter-list:before {
  content: "\e152";
}
.icon-label-off:before {
  content: "\e910";
}
.icon-label:before {
  content: "\e893";
}
.icon-error:before {
  content: "\e001";
}
.icon-info:before {
  content: "\e88f";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-camera:before {
  content: "\e90c";
}
.icon-mic:before {
  content: "\e90d";
}
.icon-videocam:before {
  content: "\e90e";
}
.icon-attachment:before {
  content: "\e2bc";
}
.icon-perm-media:before {
  content: "\e90b";
}
.icon-check-circle:before {
  content: "\e90a";
}
.icon-radio-button-unchecked:before {
  content: "\e836";
}
.icon-radio-button-checked:before {
  content: "\e837";
}
.icon-collections-bookmark:before {
  content: "\e909";
}
.icon-people:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-bug-report:before {
  content: "\e906";
}
.icon-settings:before {
  content: "\e907";
}
.icon-help:before {
  content: "\e8fd";
}
.icon-arrow-back-ios:before {
  content: "\e904";
}
.icon-arrow-forward-ios:before {
  content: "\e905";
}
.icon-date-range:before {
  content: "\e916";
}
.icon-mail-outline:before {
  content: "\e0e1";
}
.icon-visibility:before {
  content: "\e8f4";
}
.icon-visibility-off:before {
  content: "\e8f5";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-arrow-back:before {
  content: "\e5c4";
}
.icon-arrow-drop-down:before {
  content: "\e5c5";
}
.icon-arrow-drop-up:before {
  content: "\e5c7";
}
.icon-arrow-forward:before {
  content: "\e5c8";
}
.icon-expand-more:before {
  content: "\e313";
}
.icon-keyboard-arrow-left:before {
  content: "\e314";
}
.icon-keyboard-arrow-right:before {
  content: "\e315";
}
.icon-expand-less:before {
  content: "\e316";
}
.icon-menu:before {
  content: "\e5d2";
}
.icon-map:before {
  content: "\e900";
}
.icon-school:before {
  content: "\e901";
}
.icon-social:before {
  content: "\e902";
}
.icon-account-circle:before {
  content: "\e903";
}
.icon-folder:before {
  content: "\e2c7";
}
.icon-home:before {
  content: "\e88a";
}
.icon-trending_up:before {
  content: "\e8e5";
}
